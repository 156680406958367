var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticStyle: { height: "100%, width: 100%" },
      attrs: { id: "SOCIAL_FORM" },
    },
    [
      _c(
        "b-modal",
        {
          attrs: {
            size: "lg",
            "footer-class": "footerClass",
            "no-close-on-backdrop": "",
            "content-class": "shadow",
            "modal-class": "anti-shift",
          },
          on: { hidden: _vm.hidden, ok: _vm.ok },
          scopedSlots: _vm._u([
            {
              key: "modal-header",
              fn: function ({ cancel }) {
                return [
                  _c("h5", { staticClass: "custom-modal-title" }, [
                    _vm._v(" " + _vm._s(_vm.labelTitle) + " "),
                  ]),
                  _c(
                    "button",
                    {
                      staticClass: "close custom-modal-close",
                      on: {
                        click: function ($event) {
                          return cancel()
                        },
                      },
                    },
                    [_vm._v("×")]
                  ),
                ]
              },
            },
            {
              key: "modal-footer",
              fn: function ({ ok, cancel }) {
                return [
                  _vm.canEdit()
                    ? [
                        _c(
                          "b-button",
                          {
                            attrs: { size: "sm", variant: "success" },
                            on: {
                              click: function ($event) {
                                return ok()
                              },
                            },
                          },
                          [_vm._v(_vm._s(_vm.$t("button.ok")))]
                        ),
                      ]
                    : _vm._e(),
                  _c(
                    "b-button",
                    {
                      attrs: { size: "sm", variant: "danger" },
                      on: {
                        click: function ($event) {
                          return cancel()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("button.cancel")))]
                  ),
                ]
              },
            },
          ]),
          model: {
            value: _vm.modalShow,
            callback: function ($$v) {
              _vm.modalShow = $$v
            },
            expression: "modalShow",
          },
        },
        [
          _c(
            "b-form-group",
            [
              _c("label", { staticClass: "mr-1" }, [
                _vm._v(_vm._s(_vm.$t(`customer.field.socials`))),
              ]),
              _vm._l(_vm.socials, function (social, index) {
                return _c(
                  "b-row",
                  { key: index },
                  [
                    _c(
                      "b-col",
                      { attrs: { lg: "" } },
                      [
                        _c(
                          "b-form-group",
                          [
                            _c(
                              "b-input-group",
                              [
                                _c(
                                  "b-input-group-prepend",
                                  [
                                    _c("multiselect", {
                                      staticClass:
                                        "custom-dropdown-options data-kind-dropdown enable-option-icon",
                                      attrs: {
                                        "max-height": 300,
                                        options: _vm.optionSocials.map(
                                          (i) => i.value
                                        ),
                                        "custom-label":
                                          _vm.getSocialKindOptionLabel,
                                        placeholder: _vm.$t(
                                          "customer.select_socialnet_type"
                                        ),
                                        searchable: false,
                                        "allow-empty": false,
                                        showLabels: false,
                                        disabled: _vm.isReadOnly,
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "option",
                                            fn: function (props) {
                                              return [
                                                social.kind == props.option
                                                  ? _c("font-awesome-icon", {
                                                      staticClass:
                                                        "selected-option-icon",
                                                      attrs: {
                                                        icon: ["far", "check"],
                                                      },
                                                    })
                                                  : _vm._e(),
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "option__title",
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.getSocialKindOptionLabel(
                                                          props.option
                                                        )
                                                      )
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        true
                                      ),
                                      model: {
                                        value: social.kind,
                                        callback: function ($$v) {
                                          _vm.$set(social, "kind", $$v)
                                        },
                                        expression: "social.kind",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c("b-form-input", {
                                  attrs: {
                                    type: "text",
                                    readonly: _vm.isReadOnly,
                                    trim: "",
                                  },
                                  model: {
                                    value: social.data,
                                    callback: function ($$v) {
                                      _vm.$set(social, "data", $$v)
                                    },
                                    expression: "social.data",
                                  },
                                }),
                                _c(
                                  "b-input-group-append",
                                  [
                                    _c(
                                      "b-button",
                                      {
                                        attrs: {
                                          id: `SOCIAL_BTN_ADD_${index}`,
                                          variant: "secondary",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.fieldItemAdd(index, {
                                              kind: null,
                                              data: null,
                                            })
                                          },
                                        },
                                      },
                                      [
                                        _c("font-awesome-icon", {
                                          attrs: { icon: ["far", "plus"] },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "b-popover",
                                      {
                                        attrs: {
                                          boundary: "window",
                                          target: `SOCIAL_BTN_ADD_${index}`,
                                          triggers: "hover",
                                          placement: "top",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(_vm.$t("button.add")) +
                                            " "
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "b-input-group-append",
                                  [
                                    _c(
                                      "b-button",
                                      {
                                        attrs: {
                                          id: `SOCIAL_BTN_REMOVE_${index}`,
                                          variant: "secondary",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.fieldItemRemove(index, {
                                              kind: null,
                                              data: null,
                                            })
                                          },
                                        },
                                      },
                                      [
                                        _c("font-awesome-icon", {
                                          attrs: { icon: ["far", "trash-can"] },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "b-popover",
                                      {
                                        attrs: {
                                          boundary: "window",
                                          target: `SOCIAL_BTN_REMOVE_${index}`,
                                          triggers: "hover",
                                          placement: "top",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(_vm.$t("button.remove")) +
                                            " "
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                )
              }),
            ],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }